import { call, put, takeEvery, takeLatest } from "redux-saga/effects"

// Login Redux States
import {
  ADD_CUSTOM_NOTIFICATION,
  GET_CUSTOM_NOTIFICATION_LIST,
  GET_DROPDOWN_DATA,
} from "./actionTypes"
import {
  addCustomNotificationFail,
  addCustomNotificationSuccess,
  getCustomNotificationListFail,
  getCustomNotificationListSuccess,
  getDropdownDataFail,
  getDropdownDataSuccess,
} from "./actions"
import {
  addCustomNotification,
  getCustomNotificationList,
  getDropdownData,
} from "helpers/fakebackend_helper"
import { toast } from "react-toastify"
import Swal from "sweetalert2"

//Include Both Helper File with needed methods

function* fetchDropdownData() {
  try {
    const response = yield call(getDropdownData)
    yield put(getDropdownDataSuccess(response))
  } catch (error) {
    yield put(getDropdownDataFail(error))
  }
}
function* fetchCustomNotificationList() {
  try {
    const response = yield call(getCustomNotificationList)
    yield put(getCustomNotificationListSuccess(response))
  } catch (error) {
    yield put(getCustomNotificationListFail(error))
  }
}

function* onAddNewCustomNotification({ payload: { color, history } }) {
  try {
    const response = yield call(addCustomNotification, color)
    yield put(addCustomNotificationSuccess(response))
    if (response.result === 1) {
      Swal.fire({
        title: "Success",
        icon: "success",
        text: "Your Custom Notification has been sent successfully!",
      }).then(result => {
        if (result.isConfirmed) {
          history("/list-custom-notifications")
        }
      })
    }
  } catch (error) {
    yield put(addCustomNotificationFail(error))
    toast.error(error?.response?.data?.msg, { autoClose: 2000 })
  }
}

function* customNotificationSaga() {
  yield takeEvery(GET_DROPDOWN_DATA, fetchDropdownData)
  yield takeEvery(GET_CUSTOM_NOTIFICATION_LIST, fetchCustomNotificationList)
  yield takeEvery(ADD_CUSTOM_NOTIFICATION, onAddNewCustomNotification)
}

export default customNotificationSaga
