import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import "react-color-palette/css"
import withRouter from "components/Common/withRouter"
import PropTypes from "prop-types"
import { ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Form,
  FormFeedback,
  Input,
  Label,
  Row,
} from "reactstrap"
import { useFormik } from "formik"
import * as Yup from "yup"
import { useDispatch } from "react-redux"
import { addCustomNotification, getDropdownData } from "store/actions"
import { useSelector } from "react-redux"
//Import Breadcrumb

const CustomNotifications = props => {
  const dispatch = useDispatch()
  const [user, setUser] = useState(JSON.parse(localStorage.getItem("authUser")))

  document.title = "Custom Notification"

  const validation = useFormik({
    // enableReinitialize : use this  flag when initial values needs to be changed
    // enableReinitialize: true,
    initialValues: {
      title: "",
      body: "",
      redirectToDropdown: "",
      redirectTo: null,
      createdBy: "",
    },

    validationSchema: Yup.object({
      title: Yup.string().required("Please Enter Notification Title"),
      body: Yup.string().required("Please Enter Notification Body"),
    }),

    onSubmit: async values => {
      dispatch(addCustomNotification(values, props.router.navigate))
    },
  })

  const dataState = state => state.customnotification.dropdown.data
  const data = useSelector(dataState) || []

  useEffect(() => {
    dispatch(getDropdownData())
  }, [dispatch])

  useEffect(() => {
    validation.setFieldValue("redirectTo", null)
  }, [validation.values.redirectToDropdown])

  useEffect(() => {
    validation.setFieldValue("createdBy", user.data.user_id)
  }, [user])

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <CardTitle className="mb-2 font-size-18">
            <Link to="/list-custom-notifications" style={{ color: "black" }}>
              <i className="mdi mdi-arrow-left font-size-18 me-2"></i>
            </Link>
            Custom Notification
          </CardTitle>
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <Form
                    autoComplete="off"
                    onSubmit={e => {
                      e.preventDefault()
                      validation.handleSubmit()
                      return false
                    }}
                  >
                    {" "}
                    <Row>
                      <Col sm="6">
                        <div className="mb-3">
                          <Label htmlFor="title">Notification Title*</Label>
                          <Input
                            id="title"
                            name="title"
                            type="text"
                            className="form-control"
                            placeholder="Notification Title"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            invalid={
                              validation.touched.title &&
                              validation.errors.title
                                ? true
                                : false
                            }
                          />
                          {validation.touched.title &&
                          validation.errors.title ? (
                            <FormFeedback type="invalid">
                              {validation.errors.title}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm="6">
                        <div className="mb-3">
                          <Label htmlFor="body">Notification Body*</Label>
                          <Input
                            id="body"
                            name="body"
                            type="textarea"
                            className="form-control"
                            placeholder="Notification Body"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            invalid={
                              validation.touched.body && validation.errors.body
                                ? true
                                : false
                            }
                          />
                          {validation.touched.body && validation.errors.body ? (
                            <FormFeedback type="invalid">
                              {validation.errors.body}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="6">
                        <div className="mb-3">
                          <Label htmlFor="formrow-category-Input">
                            Redirect To
                          </Label>
                          <select
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            name="redirectToDropdown"
                            value={validation.values.redirectToDropdown}
                            className={`form-select ${
                              validation.touched.redirectToDropdown &&
                              validation.errors.redirectToDropdown
                                ? "is-invalid"
                                : ""
                            }`}
                          >
                            <option>Select Redirect To</option>
                            <option value="Product">Product</option>
                            <option value="Category">Category</option>
                            <option value="Subcategory">Subcategory</option>
                          </select>
                          {validation.touched.redirectToDropdown &&
                          validation.errors.redirectToDropdown ? (
                            <FormFeedback type="invalid">
                              {validation.errors.redirectToDropdown}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      {validation.values.redirectToDropdown === "Product" && (
                        <Col lg="6">
                          <div className="mb-3">
                            <Label htmlFor="formrow-category-Input">
                              Product*
                            </Label>
                            <select
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              name="redirectTo"
                              value={validation.values.redirectTo}
                              className={`form-select ${
                                validation.touched.redirectTo &&
                                validation.errors.redirectTo
                                  ? "is-invalid"
                                  : ""
                              }`}
                            >
                              {!validation.values.redirectTo && (
                                <option>Select Product</option>
                              )}

                              {(data?.ProductData || []).map(item => (
                                <option key={item.id} value={item._id}>
                                  {item.product_name}
                                </option>
                              ))}
                            </select>
                            {validation.touched.redirectTo &&
                            validation.errors.redirectTo ? (
                              <FormFeedback type="invalid">
                                {validation.errors.redirectTo}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>
                      )}
                      {validation.values.redirectToDropdown === "Category" && (
                        <Col lg="6">
                          <div className="mb-3">
                            <Label htmlFor="formrow-category-Input">
                              Category
                            </Label>
                            <select
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              name="redirectTo"
                              value={validation.values.redirectTo}
                              className={`form-select ${
                                validation.touched.redirectTo &&
                                validation.errors.redirectTo
                                  ? "is-invalid"
                                  : ""
                              }`}
                            >
                              {!validation.values.redirectTo && (
                                <option>Select Category</option>
                              )}

                              {(data?.CategoryData || []).map(item => (
                                <option key={item.id} value={item._id}>
                                  {item.category_name}
                                </option>
                              ))}
                            </select>
                            {validation.touched.redirectTo &&
                            validation.errors.redirectTo ? (
                              <FormFeedback type="invalid">
                                {validation.errors.redirectTo}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>
                      )}
                      {validation.values.redirectToDropdown ===
                        "Subcategory" && (
                        <Col lg="6">
                          <div className="mb-3">
                            <Label htmlFor="formrow-category-Input">
                              Subcategory*
                            </Label>
                            <select
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              name="redirectTo"
                              value={validation.values.redirectTo}
                              className={`form-select ${
                                validation.touched.redirectTo &&
                                validation.errors.redirectTo
                                  ? "is-invalid"
                                  : ""
                              }`}
                            >
                              {!validation.values.redirectTo && (
                                <option>Select Subcategory</option>
                              )}

                              {(data?.SubcategoryData || []).map(item => (
                                <option key={item.id} value={item._id}>
                                  {item.subcategory_name}
                                </option>
                              ))}
                            </select>
                            {validation.touched.redirectTo &&
                            validation.errors.redirectTo ? (
                              <FormFeedback type="invalid">
                                {validation.errors.redirectTo}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>
                      )}
                    </Row>
                    <Row className="align-items-center mt-3">
                      <Col className="text-end">
                        <button
                          className="w-md "
                          type="submit"
                          style={{
                            backgroundColor: "#082a6b",
                            color: "#ffffff",
                            borderTopRightRadius: "10px",
                            borderBottomLeftRadius: "10px",
                            height: "35px",
                          }}
                        >
                          Submit
                        </button>
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        <ToastContainer />
      </div>
    </React.Fragment>
  )
}

export default withRouter(CustomNotifications)

CustomNotifications.propTypes = {
  history: PropTypes.object,
}
