import React from "react"
import { Container, Row, Col } from "reactstrap"

const Footer = () => {
  return (
    <React.Fragment>
      <footer className="footer">
        <Container fluid={true}>
          <Row>
            <Col md={6}>
              <span style={{ color: "gray" }}>{new Date().getFullYear()}</span>
            </Col>
            <Col md={6}>
              <div className="text-sm-end d-none d-sm-block">
                <a
                  href="https://www.woodapples.in/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span style={{ color: "gray" }}>
                    Proudly designed by Woodapple Software Solutions PVT LTD
                  </span>
                </a>
              </div>
            </Col>
          </Row>
        </Container>
      </footer>
    </React.Fragment>
  )
}

export default Footer
