export const GET_DROPDOWN_DATA = "GET_DROPDOWN_DATA"
export const GET_DROPDOWN_DATA_SUCCESS = "GET_DROPDOWN_DATA_SUCCESS"
export const GET_DROPDOWN_DATA_FAIL = "GET_DROPDOWN_DATA_FAIL"

export const GET_CUSTOM_NOTIFICATION_LIST = "GET_CUSTOM_NOTIFICATION_LIST"
export const GET_CUSTOM_NOTIFICATION_LIST_SUCCESS =
  "GET_CUSTOM_NOTIFICATION_LIST_SUCCESS"
export const GET_CUSTOM_NOTIFICATION_LIST_FAIL =
  "GET_CUSTOM_NOTIFICATION_LIST_FAIL"

export const ADD_CUSTOM_NOTIFICATION = "ADD_CUSTOM_NOTIFICATION"
export const ADD_CUSTOM_NOTIFICATION_SUCCESS = "ADD_CUSTOM_NOTIFICATION_SUCCESS"
export const ADD_CUSTOM_NOTIFICATION_FAIL = "ADD_CUSTOM_NOTIFICATION_FAIL"
