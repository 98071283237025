import {
  ADD_NEW_COLOR_SUCCESS,
  ADD_NEW_COLOR_FAIL,
  GET_COLORS_LIST_SUCCESS,
  GET_COLORS_LIST_FAIL,
  GET_DROPDOWN_DATA_SUCCESS,
  GET_DROPDOWN_DATA_FAIL,
  ADD_CUSTOM_NOTIFICATION_SUCCESS,
  ADD_CUSTOM_NOTIFICATION_FAIL,
  GET_CUSTOM_NOTIFICATION_LIST_SUCCESS,
  GET_CUSTOM_NOTIFICATION_LIST_FAIL,
} from "./actionTypes"

const initialState = {
  dropdown: [],
  customNotificationList: [],
  customNotifications: [],
  error: {},
  loading: false,
}

const customnotification = (state = initialState, action) => {
  switch (action.type) {
    case GET_DROPDOWN_DATA_SUCCESS:
      return {
        ...state,
        dropdown: action.payload,
        loading: false,
      }

    case GET_DROPDOWN_DATA_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case GET_CUSTOM_NOTIFICATION_LIST_SUCCESS:
      return {
        ...state,
        customNotificationList: action.payload,
        loading: false,
      }

    case GET_CUSTOM_NOTIFICATION_LIST_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case ADD_CUSTOM_NOTIFICATION_SUCCESS:
      return {
        ...state,
        customNotifications: [...state.customNotifications, action.payload],
        loading: false,
      }

    case ADD_CUSTOM_NOTIFICATION_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    default:
      state = { ...state }
      break
  }
  return state
}

export default customnotification
