import {
  GET_DROPDOWN_DATA,
  GET_DROPDOWN_DATA_SUCCESS,
  GET_DROPDOWN_DATA_FAIL,
  GET_CUSTOM_NOTIFICATION_LIST,
  GET_CUSTOM_NOTIFICATION_LIST_SUCCESS,
  GET_CUSTOM_NOTIFICATION_LIST_FAIL,
  ADD_CUSTOM_NOTIFICATION,
  ADD_CUSTOM_NOTIFICATION_SUCCESS,
  ADD_CUSTOM_NOTIFICATION_FAIL,
} from "./actionTypes"

export const getDropdownData = () => ({
  type: GET_DROPDOWN_DATA,
})

export const getDropdownDataSuccess = data => ({
  type: GET_DROPDOWN_DATA_SUCCESS,
  payload: data,
})

export const getDropdownDataFail = error => ({
  type: GET_DROPDOWN_DATA_FAIL,
  payload: error,
})
export const getCustomNotificationList = () => ({
  type: GET_CUSTOM_NOTIFICATION_LIST,
})

export const getCustomNotificationListSuccess = colors => ({
  type: GET_CUSTOM_NOTIFICATION_LIST_SUCCESS,
  payload: colors,
})

export const getCustomNotificationListFail = error => ({
  type: GET_CUSTOM_NOTIFICATION_LIST_FAIL,
  payload: error,
})

export const addCustomNotification = (color, history) => ({
  type: ADD_CUSTOM_NOTIFICATION,
  payload: { color, history },
})

export const addCustomNotificationSuccess = color => ({
  type: ADD_CUSTOM_NOTIFICATION_SUCCESS,
  payload: color,
})

export const addCustomNotificationFail = error => ({
  type: ADD_CUSTOM_NOTIFICATION_FAIL,
  payload: error,
})
